<template>
  <div class="page-table only-y mainDiv">
    <div class="card-base baslikBg animated fadeInUp">
      Tutanak Listesi
      <div class="tanimlamaButon">
        <el-tooltip
          :content="$t('src.views.apps.genel.refresh')"
          :open-delay="500"
          placement="top"
        >
          <el-button
            v-on:click="refreshPage()"
            size="mini"
            type="text"
            icon="el-icon-refresh"
            class="mr-5"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          :content="$t('src.views.apps.genel.yeniKaydet')"
          :open-delay="500"
          placement="bottom"
        >
          <el-button
            v-on:click="routeYonetici('Tutanak Tanımla')"
            size="mini"
            type="text"
            icon="el-icon-plus"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <div
      class="page-header card-base card-shadow--medium animated fadeInUp"
      v-loading="loading || stateLoading || searchLoading"
      :element-loading-text="
        loading
          ? $t('src.views.apps.yonetici.liste.listLoading')
          : stateLoading
          ? $t('src.views.apps.yonetici.liste.stateLoading')
          : 'Personel Aranıyor. Lütfen Bekleyiniz.'
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-row class="mt-0 mb-10">
        <el-col :lg="10" :md="12" :sm="24" :xs="24">
          <div>
            <el-tooltip
              v-if="getTutanakSearchProjeID"
              class="item"
              effect="dark"
              content="Proje Bazlı Aramayı Kapat"
              placement="bottom"
            >
              <el-button
                @click="
                  $store.commit('setTutanakSearchProjeID', null),
                    getTutanakList()
                "
                class="mr-10"
                size="mini"
                type="danger"
                icon="el-icon-close"
                circle
              />
            </el-tooltip>
            <el-radio-group
              size="small"
              v-on:change="handleChange($event)"
              v-model="radio"
              fill="#E5F5F9"
              text-color="gray"
              :disabled="searchLoading"
            >
              <el-radio-button label="1">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #47a992; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '1'"
                    >Aktif Tutanaklar</label
                  >
                </transition>
              </el-radio-button>
              <el-radio-button label="2">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #3595cf; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '2'"
                    >Tamamlanan Tutanaklar</label
                  >
                </transition>
              </el-radio-button>
              <el-radio-button label="3">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #bf3131; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '3'"
                    >İptal Edilen Tutanaklar</label
                  >
                </transition>
              </el-radio-button>
              <el-radio-button label="0">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #163020; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="sozlesmeKategoriID === '0'"
                    >Pasif Tutanaklar</label
                  >
                </transition>
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col
          :lg="14"
          :md="12"
          :sm="24"
          :xs="24"
          v-if="yetkiTuru == '1' || yetkiTuru == '2'"
        >
          <ul class="islemler" v-if="isDesktop">
            <li class="detay"><i class="mdi mdi-eye"></i> Tutanak Detay</li>
          </ul>
          <ul class="islemler2" v-else>
            <li
              class="duzenle"
              v-if="
                yetkiTuru == '1' ||
                (yetkiTuru == '2' && yetkiListesi.includes('YK-musteriU100'))
              "
            >
              <i class="mdi mdi-circle-edit-outline"></i>
              {{ $t("src.views.apps.musteri.liste.table.editCustomer") }}
            </li>
            <li v-if="selectIcon === '1'" class="sil">
              <i class="mdi mdi-trash-can"></i>
              {{ $t("src.views.apps.musteri.liste.table.setPassive") }}
            </li>
            <li v-else class="aktif">
              <i class="mdi mdi-play"></i>
              {{ $t("src.views.apps.musteri.liste.table.setActive") }}
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-table
        :data="tutanakListesi"
        style="width: 100%"
        border
        stripe
        class="tutanak-table"
      >
        <el-table-column prop="tutanakID" label="ID" width="80" align="center">
          <template slot-scope="prop">
            <div class="new-line-text">
              {{ prop.row.tutanakID }}
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="tutanakAra"
              placeholder="Sayı Ara"
              v-model="sayiAra"
              v-debounce:500ms="tutanakAra"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column prop="sayi" label="Sayı" width="120">
            <template slot-scope="prop">
              <div class="new-line-text">{{ prop.row.sayi }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="tutanakAra"
              placeholder="Konu Ara"
              v-model="konuAra"
              v-debounce:500ms="tutanakAra"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column prop="konu" label="Konu">
            <template slot-scope="prop">
              <div class="new-line-text">{{ prop.row.konu }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="tutanakAra"
              placeholder="İçerik Ara"
              v-model="icerikAra"
              v-debounce:500ms="tutanakAra"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column prop="icerikAra" label="İçerik">
            <template slot-scope="prop">
              <div class="new-line-text">{{ prop.row.icerik }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="tutanakAra"
              placeholder="Yetkili Ara"
              v-model="yetkiliAra"
              v-debounce:500ms="tutanakAra"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column prop="yetkiliAdSoyad" label="Yetkili">
            <template slot-scope="prop">
              <div class="new-line-text">{{ prop.row.yetkiliAdSoyad }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="tutanakAra"
              placeholder="Oluşturan kişiyi ara"
              v-model="olusturanAra"
              v-debounce:500ms="tutanakAra"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column prop="olusturan" label="Oluşturan">
            <template slot-scope="prop">
              <div class="new-line-text">{{ prop.row.olusturan }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header"></template>

          <el-table-column prop="" label="İletişim Türü">
            <template slot-scope="prop">
              <div class="new-line-text">
                {{
                  prop.row.iletisimTuru === "1"
                    ? "Telefon"
                    : prop.row.iletisimTuru === "2"
                    ? "E-Posta"
                    : prop.row.iletisimTuru === "3"
                    ? "Toplantı"
                    : prop.row.iletisimTuru === "4"
                    ? "Yüz yüze"
                    : ""
                }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input
              clearable
              v-on:clear="tutanakAra"
              placeholder="Yer Ara"
              v-model="yerAra"
              v-debounce:500ms="tutanakAra"
              size="mini"
              :key="props.$index"
            ></el-input>
          </template>
          <el-table-column prop="yer" label="Yer" width="120">
            <template slot-scope="prop">
              <div class="new-line-text">{{ prop.row.yer }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-date-picker
              size="small"
              class="select-wide"
              type="date"
              placeholder="Tarih Ara"
              v-model="tarihAra"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="tutanakAra"
              :key="props.$index"
            >
            </el-date-picker>
          </template>
          <el-table-column prop="olusumTarihi" label="Tarih">
            <template slot-scope="prop">
              <div class="new-line-text">
                {{ prop.row.olusumTarihi.slice(0, 16) }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <el-table-column prop="tutanakID" label="İşlemler" align="right">
            <template slot-scope="scope">
              <div class="tutanak-table-last-column">
                <el-button
                  @click="tutanakDetay(scope.row)"
                  type="text"
                  class="islem-button"
                  style="margin-right: 10px"
                  icon="mdi mdi-eye mdi-24px"
                >
                </el-button>
                <el-dropdown
                  @command="tutanakDurumChange(scope.row, $event)"
                  size="small"
                  trigger="click"
                  class="tutanak-durum-dropdown"
                >
                  <span class="el-dropdown-link"
                    >Durum

                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      command="1"
                      :disabled="sozlesmeKategoriID == 1"
                      icon="mdi mdi-account"
                      style="color: #47a992"
                      >Aktif
                    </el-dropdown-item>
                    <el-dropdown-item
                      command="2"
                      :disabled="sozlesmeKategoriID == 2"
                      icon="mdi mdi-account"
                      style="color: #3595cf"
                      >Tamamlandı
                    </el-dropdown-item>
                    <el-dropdown-item
                      command="0"
                      :disabled="sozlesmeKategoriID == 0"
                      icon="mdi mdi-account"
                      style="color: #163020"
                      >Pasif
                    </el-dropdown-item>
                    <el-dropdown-item
                      command="3"
                      :disabled="sozlesmeKategoriID == 3"
                      icon="mdi mdi-account"
                      style="color: #bf3131"
                      >İptal
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div
        v-if="tutanakListesi.length == []"
        style="
          width: 100%;
          height: 50px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        Tutanak Kaydı Bulunamadı
      </div>
      <el-pagination
        background
        layout="sizes,prev, pager, next, jumper, total"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="pageChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page"
        :next-click.sync="page"
        :prev-click.sync="page"
        @next-click="pageChange"
        @prev-click="pageChange"
      >
      </el-pagination>
    </div>
    <tutanak-detay
      v-if="dialogVisibleYonetici"
      :dialogvisible.sync="dialogVisibleYonetici"
      :userdata="userDataBGSurec"
    />
  </div>
</template>

<script>
import yoneticiService from "../../../WSProvider/YoneticiService";
import UserDialog from "@/components/UserDialog";
import TutanakDetay from "@/components/TutanakDetay";
import notification from "../../../notification";
import functions from "../../../functions";
import jwt_decode from "jwt-decode";
import EventBus from "@/components/event-bus";
import tutanakService from "@/WSProvider/TutanakService";

let userDataBGSurec = "";
export default {
  name: "TutanakListesi",
  components: { TutanakDetay },
  mounted() {
    window.addEventListener("resize", this.checkDevice);
    const self = this;
    if (localStorage.getItem("userDataBGSurec")) {
      userDataBGSurec = jwt_decode(localStorage.getItem("userDataBGSurec"));
      if (userDataBGSurec["yetkiTuru"]) {
        if (userDataBGSurec["yetkiTuru"] === "1") {
          self.root = true;
          self.yetkiList.yoneticiSil = true;
          self.yetkiList.yoneticiDetayla = true;
          self.yetkiList.yoneticiGuncelleme = true;
          self.yetkiList.sifreGonder = true;
        }
        if (userDataBGSurec["yetkiTuru"] === "2") {
          self.yetkiList.yoneticiSil = true;
          self.yetkiList.yoneticiDetayla = true;
          self.yetkiList.yoneticiGuncelleme = true;
          self.yetkiList.sifreGonder = true;
        }
      }
    }
    this.getTutanakList();
    EventBus.$on("tutanakListesi", function (bool) {
      if (bool) {
        self.getTutanakList();
        // self.getYoneticiCount();
      }
    });
    if (this.getTutanakSearchProjeID) {
      this.tutanakAra();
    }
  },
  watch: {
    page(val) {
      this.postPage = (val - 1) * this.sizes;
    },
    sehir(newValue) {
      if (newValue === "") this.musteriForm.ilce = "";
    },
  },
  data() {
    return {
      stateLoading: false,
      searchLoading: false,
      loading: false,
      isDesktop: false,
      page: 0,
      sizes: 10,
      postPage: 0,
      total: null,
      selectIcon: "1",
      radio: "1",
      root: false,
      yetkili: false,
      yetkiList: {
        yoneticiDetayla: false,
        yoneticiGuncelleme: false,
        yoneticiSil: false,
        sifreGonder: false,
      },
      dialogVisibleYonetici: false,
      userDataBGSurec: {},
      deleteDialogVisible: false,
      selection: "",
      selectionIndex: "",
      tutanakListesi: [],
      yetkiliAra: "",
      konuAra: "",
      icerikAra: "",
      sayiAra: "",
      yerAra: "",
      olusturanAra: "",
      tarihAra: "",
      sozlesmeKategoriID: "1",
      birim: "",
    };
  },
  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      // this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      this.birim = this.$store.getters.getSozlesmeKategoriID;
    }
  },
  beforeDestroy() {
    this.$store.commit("setTutanakSearchProjeID", null);
    window.removeEventListener("resize", this.checkDevice);
  },
  methods: {
    tutanakDetay(tutanak) {
      this.userDataBGSurec = tutanak;
      this.dialogVisibleYonetici = true;
    },
    refreshPage() {
      this.stateLoading = false;
      this.loading = false;
      this.page = 1;
      this.postPage = 0;
      this.getTutanakList();
      //  this.getYoneticiCount();
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 768;
    },
    yoneticiDetay(scope) {
      this.userDataBGSurec = scope;
      this.dialogVisibleYonetici = true;
    },
    sifreSmsGonder(row) {
      this.$confirm(
        "Şifreyi sms göndermek istediğinize emin misiniz?",
        this.$t("src.views.apps.genel.uyari"),
        {
          confirmButtonText: this.$t("src.views.apps.genel.yes"),
          cancelButtonText: this.$t("src.views.apps.genel.no"),
          type: "warning",
        }
      )
        .then(() => {
          this.loading = true;
          yoneticiService
            .sifreSmsGonder(row.ID)
            .then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataBGSurec", response.token);
                this.resetForm(formName);
                functions.sayfaKapat("personelList", this);
              }
              this.loading = false;
              notification.Status("success", this, response.msg);
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            });
        })
        .catch((e) => {});
    },
    handleChange(event) {
      this.yetkiliAra = "";
      this.konuAra = "";
      this.icerikAra = "";
      this.sayiAra = "";
      this.yerAra = "";
      this.olusturanAra = "";
      this.tarihAra = "";

      this.$store.commit("setTutanakSearchProjeID", null);
      this.postPage = 0;
      this.page = 1;
      this.sozlesmeKategoriID = this.radio;
      this.getTutanakList();
      // this.getYoneticiCount();
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
    },
    handleChange2(event) {
      this.postPage = 0;
      this.page = 1;
      this.selectIcon = event;
      this.durum = this.radio;
      this.getTutanakList();
      // this.getYoneticiCount();
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
    },
    getTutanakList() {
      this.loading = true;
      tutanakService
        .tutanakListesi(this.radio, this.postPage, this.sizes)
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("userDataBGSurec", response.token);
            this.tutanakListesi = response.data;
            this.total = response.count;
          }
          this.loading = false;
        })
        .catch((err) => {
          if (err.responseJSON) {
            let error = err.responseJSON;
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.tutanakListesi = [];
            } else notification.Status("errorMsg", this, error.errMsg);
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        });
    },
    tutanakDurumChange(tutanak, durum) {
      this.stateLoading = true;
      tutanakService
        .tutanakDurumChange(tutanak.tutanakID, durum)
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("userDataBGSurec", response.token);
            notification.Status("success", this, response.msg);
            this.getTutanakList();
            // this.getYoneticiCount();
          }
          this.stateLoading = false;
        })
        .catch((err) => {
          if (err.responseJSON) {
            let error = err.responseJSON;
            if (error.status == 401) {
              notification.Status(150, this);
            } else notification.Status("errorMsg", this, error.errMsg);
          } else {
            //notification.Status(503, this)
          }
          this.stateLoading = false;
        });
    },
    tutanakAra() {
      this.postPage = 0;
      this.page = 1;
      if (
        this.getTutanakSearchProjeID != null ||
        this.sayiAra.length > 0 ||
        this.konuAra.length > 0 ||
        this.icerikAra.length > 0 ||
        this.yetkiliAra.length > 0 ||
        this.olusturanAra.length > 0 ||
        this.yerAra.length > 0 ||
        (this.tarihAra && this.tarihAra.length > 0)
      ) {
        try {
          this.searchLoading = true;
          // tutanakService.tutanakListesiAra(this.getTutanakSearchProjeID, this.birim == 1 ? this.sozlesmeKategoriID : this.birim, this.postPage, this.sizes, this.sayiAra, this.konuAra, this.icerikAra, this.yetkiliAra, this.olusturanAra, this.yerAra, this.tarihAra).then(response => {
          tutanakService
            .tutanakListesiAra(
              this.getTutanakSearchProjeID,
              this.sozlesmeKategoriID,
              this.postPage,
              this.sizes,
              this.sayiAra,
              this.konuAra,
              this.icerikAra,
              this.yetkiliAra,
              this.olusturanAra,
              this.yerAra,
              this.tarihAra
            )
            .then((response) => {
              if (response.status == 200) {
                this.tutanakListesi = response.data;
                this.total = response.count;
              }
              localStorage.setItem("userDataBGSurec", response.token);
              this.searchLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.tutanakListesi = [];
                  notification.Status("warning", this, error.msg);
                }
              }
              this.searchLoading = false;
            });
        } catch (e) {
          // notification.Status("danger", this, "An error occurred during the customer search process.")
          this.searchLoading = false;
        }
      } else {
        this.getTutanakList();
      }
    },
    routeYonetici(title, scope) {
      //console.log("scope")
      //console.log(scope)
      if (scope) {
        this.$store.commit("changeYoneticiUpdateData", scope);
      }
      functions.routeSayfa(title, this);
    },
    handleSizeChange(val) {
      this.sizes = val;
      this.getTutanakList();
      // this.getYoneticiCount();
    },
    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getTutanakList();
    },
    indexMethod(index) {
      return (this.page - 1) * 10 + index + 1;
    },
  },
  computed: {
    getTutanakSearchProjeID() {
      return this.$store.getters.getTutanakSearchProjeID;
    },
    yetkiTuru() {
      return this.$store.state.yetkiTuru;
    },
  },
};
</script>
<style lang="scss" scoped>
.tutanak-table-last-column {
  display: flex;
  place-content: end;
  place-items: center;
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

.el-radio-button--mini .el-radio-button__inner label {
  position: relative;
  bottom: 5px;
}
</style>
<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.el-radio-button--mini .el-radio-button__inner {
  padding: 0 10px !important;
  font-size: 11px;
  border-radius: 0;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
<style>
.el-table .cell {
  line-height: 17px !important;
}

.el-table thead {
  line-height: 30px !important;
}

.personelButon {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  font-size: 0 !important;
  line-height: 1 !important;
}

.buttonDel {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  font-size: 0 !important;
  line-height: 1 !important;
}

.tutanak-table td {
  height: 40px;
}

.tutanak-durum-dropdown {
  cursor: pointer;
}
</style>
